import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class ShipmentBundlesController extends Controller {
  static get targets() {
  	return [
      'selected', 'selectedList', 'nextBtn', 'row', 'cancelBtn', 'bundleFilters', 'confirmInfo',
      'newList', 'confirmList', 'generalFilters', 'readySelectedList', 'createBtn'
    ]
  }

  connect(){
    console.log("Index connected")
    this.cancelBtnTarget.classList.add('d-none')
    this.createBtnTarget.classList.add('d-none')
    this.confirmInfoTarget.classList.add('d-none')
    this.confirmListTarget.classList.add('d-none')
    this.toUpdate = new Object()
    for(const checkbox of this.selectedTargets){
      if(checkbox.checked){
        this.toUpdate[checkbox.value] = checkbox.value
      }
      else{
        delete this.toUpdate[checkbox.value]
      }
    }
    this.refreshSelectedList()
  }

  select(e) {
    let checked = e.currentTarget.checked
    if(checked) {
      this.toUpdate[e.currentTarget.value] = e.currentTarget.value
    }
    else {
      delete this.toUpdate[e.currentTarget.value]
    }
    this.refreshSelectedList()
  }

  refreshSelectedList(e) {
    var listHtml = ""
    Object.keys(this.toUpdate).forEach(shipmentBundleId => {
        listHtml += "<li>" +  this.toUpdate[shipmentBundleId] +
                    ` <input name="bundle_ids[]" type="hidden" value=${shipmentBundleId}>` +
          "</li>"
    })
    this.selectedListTarget.innerHTML = listHtml
    this.readySelectedListTarget.innerHTML = listHtml
    this.cancelBtnTarget.classList.add('d-none')
    this.createBtnTarget.classList.add('d-none')
    if(listHtml == ""){
      this.cancelBtnTarget.classList.add('d-none')
      this.createBtnTarget.classList.add('d-none')
    }
    else{
      this.nextBtnTarget.classList.remove('d-none')
    }
  }

  showSelectedOnly() {
    this.cancelBtnTarget.classList.remove('d-none');
    this.createBtnTarget.classList.remove('d-none');
    this.nextBtnTarget.classList.add('d-none');
    this.bundleFiltersTarget.classList.add('d-none');
    this.generalFiltersTarget.classList.add('d-none');
    this.newListTarget.classList.add('d-none');
    this.confirmListTarget.classList.remove('d-none');
    this.confirmInfoTarget.classList.remove('d-none');
  
    for (const row of this.rowTargets) {
      row.classList.add('d-none');
    }
    for (const checkbox of this.selectedTargets) {
      if (checkbox.checked) {
        const row = this.rowTargets.find(function (element) {
          return element.id == checkbox.value;
        });
        row.classList.remove('d-none');
        checkbox.disabled = true;
      }
    }

    const dateInput = document.querySelector('[data-datetime-target="inputDateNoHour"]');
    const warehouseSelect = document.getElementById('warehouse-select');
    const customerSelect = document.getElementById('customer-select');
    const commentsTextarea = document.getElementById('comments-textarea');
  
    const dateSection = document.getElementById('date');
    const warehouseSection = document.getElementById('warehouse');
    const customerSection = document.getElementById('customer');
    const notesSection = document.getElementById('notes');

    dateSection.textContent = `Date: ${dateInput.value || 'No date selected'}`;
    warehouseSection.textContent = `Warehouse: ${warehouseSelect.options[warehouseSelect.selectedIndex].text || 'All Warehouses'}`;
    customerSection.textContent = `Customer: ${customerSelect?.options[customerSelect.selectedIndex]?.text || 'All Customers'}`;
    notesSection.textContent = `Notes: ${commentsTextarea.value || 'No notes provided'}`;
  }

  cancel(){
    this.refreshSelectedList()
    this.cancelBtnTarget.classList.add('d-none')
    this.createBtnTarget.classList.add('d-none')
    this.nextBtnTarget.classList.remove('d-none')
    this.bundleFiltersTarget.classList.remove('d-none')
    this.generalFiltersTarget.classList.remove('d-none')
    this.newListTarget.classList.remove('d-none')
    this.confirmInfoTarget.classList.add('d-none')
    this.confirmListTarget.classList.add('d-none')
    for(const row of this.rowTargets){
      row.classList.remove('d-none')
    }
    for(const checkbox of this.selectedTargets){
      checkbox.disabled = false
    }
  }
}
